<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";



import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import ProgressDialog from './components/progress_dialog'


import { getPMApi } from '@/api/pm'
import { financial } from '@/api/misc'
import moment from 'moment'

/**
 * Setup PM  component
 */
export default {
  page: {
    title: "PM",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  validations() {

    return {

    }


  },


  methods: {

    formSubmit() {

      let data = {
        expenses: this.listingData
      }
      let data_error = false
      for (let invoice of this.listingData) {
        if (invoice.pm_error || invoice.supplier_error) {
          this.has_data_error = true
          break
        }
      }

      if (data_error == true) {
        this.has_data_error = true
        return
      }
      this.$bvModal.show('ProgressDiloag')
      getPMApi().batch_expense(data).then(res => {
        this.$bvModal.hide('ProgressDiloag')
        if (res.errCode == 0) {
          this.$alertify.message("Add Expense successfully");
          this.$router.push({ name: 'pm-property-list' })
        } else {
          this.$alertify.error(" Property Add Expense failed:" + res.errCode + "  msg:" + res.msg);
        }
      })


    },




    onInvoidPdfUploaded(ev, resp) {

      if (resp[0].errCode == 200) {
        this.file = {
          save_route: resp[0].response.url,
          save_route_key: resp[0].response.key,
          file_name: resp[0].response.filename,
          size: resp[0].response.size,
          type: resp[0].response.type
        }
      }
    },



    removePMEntry(data) {
      this.listingData = this.listingData.filter(e => data.expense_id != e.expense_id)
      this.total_amount = this.listingData.reduce((a, c) => a + Number(c.amount), 0)
    },




    onExpenseListUploaded(data, resp) {

      if (resp.errCode == 0) {
        this.has_data_error = false


        resp.data.list.map(e => {
          e.property_id = e.pm_id
          e.expense_id = this.expense_temp_id
          e.supplier_name = e.vendor
          e.invoice = e.invoice_number
          e.date = e.invoice_date
          e.type = 'Expense'
          e.sub_type = e.exp_type
          e.supplier_code = e.supplier.supplier_code
          e.supplier_name = e.supplier.name
          e.supplier_address = e.supplier.address

          this.expense_temp_id += 1
          this.listingData.push(e)
          this.total_amount += Number(e.total)
        })

        if (resp.data.err != 0) {
          this.has_data_error = true
          this.data_err_msg = resp.data.note
        }
      } else {
        this.has_data_error = true
        this.data_err_msg = resp.msg
        this.$alertify.error("Upload Failed Ret:" + resp.errCode + "  err:" + resp.msg);
      }

    },


    getValue(item) {

      if (item.label == 'Amount') {
        return '$' + this.total_amount.toLocaleString()
      } else if (item.label == 'Post Date') {
        return 'Total: '
      } else {
        return ''
      }
    }



  },

  data() {
    return {
      title: "PM",
      items: [
        {
          text: "PM",
          href: "/pm/property_list",
        },
        {
          text: "Post PM Expense",
          active: true,
        },
      ],


      has_data_error: false,
      data_err_msg: '',
      file: {},
      post_date: '',
      expense_type: 'Expense',
      selected_pm: '',
      selected_total: '',
      selected_amount: '',
      selected_gst: '',
      expense_sub_type: 'Cleaning',
      note: '',

      supplier_code: '',
      supplier_name: '',
      supplier_address: '',
      expense_temp_id: 12001,
      invoice_number: '',
      pm_month: '',

      searched_supplier_data: [],
      listingData: [],
      searched_pm_data: [],
      dropzoneOptions: {
        url: "/AwsFileUpload/s3_upload_multi",
        thumbnailWidth: 150,
        maxFilesize: 50,
      },

      total_amount: 0
    };
  },
  components: {
    Layout,
    PageHeader,

    vueDropzone: vue2Dropzone,

    ProgressDialog

  },
  mounted() {
    if (this.$route.query.property_id) {
      this.selected_pm = new String(this.$route.query.property_id)
    }


    let today = moment()
    this.pm_month = today.format("YYYYMM")

  },

  watch: {
    selected_pm(new_pm_str) {
      this.onSearchPM(new_pm_str)
    },

    supplier_name(new_supplier) {
      this.onSearchSupplier(new_supplier)
    },

    selected_amount(new_val) {
      this.selected_gst = financial(Number(new_val) * 0.05, 2)
      this.selected_total = financial(Number(this.selected_amount) + Number(this.selected_gst), 2)
    },

    selected_gst(new_val) {
      this.selected_total = financial(Number(this.selected_amount) + Number(new_val), 2)
    },
  }
};
</script>



<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-modal centerd id="ProgressDiloag" title="Waiting...." size="lg" hide-footer hide-header-close
      no-close-on-backdrop no-close-on-esc>
      <ProgressDialog />
    </b-modal>

    <div class="row">

      <div class="col-xxl-12 col-lg-12">
        <div class="card">
          <div class="card-body">

            <form class="needs-validation" @submit.prevent="formSubmit">

              <div class="row">
                <label class="form-label">Upload Template</label>
              </div>
              <hr class="mt-1">


              <div class="row">
                <div class="col-md-6">
                  <div class="mb-3">
                    <vue-dropzone id="expense-dropzone" :use-custom-slot="true"
                      :options="{ url: '/adm/pm/expense/upload_invoice', maxFilesize: 10, maxFiles: 1, paramName: 'invoice' }"
                      @vdropzone-success="onExpenseListUploaded">
                      <div class="dropzone-custom-content">
                        <i class="display-4 text-muted "></i>
                        <h4>Drop expense file to here.</h4>
                      </div>
                    </vue-dropzone>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-3">
                    <vue-dropzone id="invoice-dropzone" :use-custom-slot="true" :options="dropzoneOptions"
                      @vdropzone-success="onInvoidPdfUploaded">
                      <div class="dropzone-custom-content">
                        <i class="display-4 text-muted "></i>
                        <h4>Drop invoice PDF file to here.</h4>
                      </div>
                    </vue-dropzone>
                  </div>
                </div>

              </div>



              <div class="row">
                <div class="mb-3">
                  <b-alert show variant="danger" v-if="has_data_error"><span
                      v-html="data_err_msg.replaceAll('\n', '<br />')"></span> </b-alert>
                </div>
              </div>



              <div class="row">
                <label class="form-label">List </label>
              </div>
              <hr class="mt-1">

              <div class="row">
                <div class="col-md-12">
                  <div class="d-flex flex-wrap gap-2 justify-content-start d-flex align-items-left">
                    <b-button type="submit" class="btn-primary" variant="primary"
                      :disabled="listingData.length <= 0">Submit</b-button>
                    <b-button variant="soft-light" type="button"
                      @click="$router.push({ name: 'pm-property-list' })">Cancel</b-button>
                  </div>
                </div>
              </div>
              <div data-simplebar>
                <div class="table-responsive mt-3">
                  <div class="table align-middle table-nowrap">
                    <b-table :items="listingData"
                      :fields="['Code', 'Type', 'PostDate', 'Total', 'Invoice', 'Note', 'Supplier', 'Address', 'Remove']"
                      thead-class="bg-light" :head-variant="'light'" hover show-empty responsive="sm"
                      :per-page="listingData.length" class="table-check" foot-clone>
                      <template #cell(Code)="data">
                        {{ data.item.pm_id }}
                      </template>
                      <template #cell(Total)="data">
                        ${{ data.item.total.toLocaleString() }}
                      </template>
                      <template #cell(PostDate)="data">
                        {{ data.item.date }}
                      </template>
                      <template #cell(Type)="data">
                        {{ data.item.sub_type }}
                      </template>
                      <template #cell(Note)="data">
                        {{ data.item.note }}
                      </template>
                      <template #cell(Invoice)="data">
                        {{ data.item.invoice }}
                      </template>

                      <template #cell(Supplier)="data">
                        {{ data.item.supplier_name }}
                      </template>
                      <template #cell(Address)="data">
                        {{ data.item.supplier_address }}
                      </template>
                      <template #cell(Remove)="data">
                        <div class="row icon-demo-content-10">
                          <div class="col-xl-3 col-lg-4 col-sm-6" @click="removePMEntry(data.item)"><i
                              class="uil-times font-size-4"></i></div>
                        </div>
                      </template>

                      <template #foot()="data">
                        <i>{{ getValue(data) }}</i>
                      </template>


                    </b-table>
                  </div>
                </div>
              </div>




            </form>
            <!-- end form -->
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
